const CloudflareWebAnalytics = () => {
    return (
        <>
            {/* <!-- Cloudflare Web Analytics --> */}
            <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "84e00fece7c841c589715d0b2df097b1"}'></script>
            {/* <!-- End Cloudflare Web Analytics --> */}
        </>
    )
}

export default CloudflareWebAnalytics;